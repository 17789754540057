import { max } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AddSocCode from "../../pages/townson-dashboard-list/components/client-data-upload-component/add-soc-code";
import AddSocCodeOnet from "../../pages/townson-dashboard-list/components/client-data-upload-component/add-soc-code-search-onet";
import CSVUpload from "../../pages/townson-dashboard-list/components/client-data-upload-component/csv-upload";
import CustomersUpload from "../../pages/townson-dashboard-list/components/client-data-upload-component/customers-upload";
import { isSearchJobTitleEnabled } from "../../redux/user/utils";

import {
	removeUploadedCsv,
	validatePDFFiles,
} from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";
import { checkValueIsPositiveDecimal } from "../../utils/constants";
import InputToggle from "../InputToggle/InputToggle";
import Loader from "../loader/loader.component";
import CleanModal from "../modal/CleanModal";
import {
	createDashBoard,
	getSocCodeList,
	handleCustomersOnChangeFile,
	handleOnChangeFile,
	handleOnChangeLocationFile,
	handleOnCurrentLocationDescriptionChange,
	handleSocCodeUpdate,
	handleToggleCheckboxSocCode,
	resumeProcessing,
	uploadCSV,
	uploadCustomers,
	validateStep,
} from "./helper";
import AddEditLocations from "./locations/AddEditLocations";
import DashboardTitle from "./title/DashboardTitle";

function CreateCloneBusinessInsightsDashboard({ openBiModal, setOpenBiModal }) {
	const user = useSelector((state) => state.user);
	const maxBiUploads = useSelector((state) => state.user.company_bi_upload_limit);

	const maxAllowed = maxBiUploads;
	const arrayLength = maxAllowed;
	const dynamicArray = Array.from({ length: arrayLength }, (_, index) => index);

	const [proposedLocationIndex, setProposedLocationIndex] = useState(1);
	const [location, setLocation] = useState({
		currentLocation: {},
		proposedLocations: [],
	});

	const [locationDescription, setLocationDescription] = useState([]);
	const [step, setStep] = useState(1);

	const currentDashboardData = useSelector((state) => state.scoreInsightsDashboard.currentDashboard);

	const [dashboardTitle, setDashboardTitle] = useState("Starting title");

	useEffect(() => {
		if (currentDashboardData && currentDashboardData?.name) {
			setDashboardTitle("Cloned from Score Insights - " + currentDashboardData?.name);
			setSelectedSocCode(currentDashboardData.soc);
		}
	}, [currentDashboardData]);

	const [csvFileName, setCsvFileName] = useState();
	const [csvFile, setCsvFile] = useState();
	const [customersFile, setCustomersFile] = useState();
	const [progressFileUpload, setProgressFileUpload] = useState(0);
	const [errorFileUpload, setErrorFileUpload] = useState();
	const [customersFileName, setCustomersFileName] = useState();
	const [customersProgressFileUpload, setCustomersProgressFileUpload] = useState(0);
	const [customersErrorFileUpload, setCustomersErrorFileUpload] = useState();
	const [useOnetSearch, setUseOnetSearch] = useState(false);
	const [selectedSocCode, setSelectedSocCode] = useState([]);
	const [socCodes, setSocCodes] = useState([]);
	const [socCodeList, setSocCodeList] = useState([]);
	const [errorSOCValidation, setErrorSOCValidation] = useState();
	const [currentLocationDescription, setCurrentLocationDescription] = useState({});
	const [validationError, setValidationError] = useState({
		title: { error: null, message: "Please add a title to the dashboard" },
		location: { error: null, message: "At least one location is required" },
		socCode: { error: null, message: "Please select at least one SOC code" },
	});
	const [loading, setLoading] = useState(false);
	const [trigger, setTrigger] = useState(false);
	const [csvError, setCsvError] = useState([]);
	const [locationPDFFilesUpload, setlocationPDFFilesUpload] = useState(0);
	const [locationPDFFiles, setLocationPDFFiles] = useState([]);

	// Check for reviewing CSV
	const [approvingCsvUpload, setApprovingCsvUpload] = useState(false);
	// Proceed to next step for CSV Upload
	const [proceedWithCsvUpload, setProceedWithCsvUpload] = useState(false);
	// Show csv loader for processing
	const [csvProcessingLoading, setCSVProcessingLoading] = useState(false);
	const [tempResults, setTempResults] = useState([]);
	const [tempData, setTempData] = useState();
	const [csvApproval, setCsvApproval] = useState(null);
	const [numberOfRows, setNumberOfRows] = useState(0);
	const [locationPDFFilesErrorUpload, setLocationPDFFilesErrorUpload] = useState();
	const [locationPDFFilesNames, setLocationPDFFilesNames] = useState([]);

	useEffect(() => {
		if (!approvingCsvUpload) {
			setCsvApproval(null);
		}
	}, [approvingCsvUpload]);

	const handleOnDashboardTitleChange = (e) => {
		setDashboardTitle(e.target.value);
	};

	useEffect(() => {
		if (proceedWithCsvUpload) {
			resumeProcessing(
				setCSVProcessingLoading,
				setCsvError,
				setLocation,
				setProposedLocationIndex,
				setLocationDescription,
				tempResults,
				maxAllowed,
				tempData,
			);
		}
	}, [proceedWithCsvUpload]);

	useEffect(() => {
		getSocCodeList(setSocCodeList);
	}, []);

	const handleNextStep = () => {
		if (validateStep(validationError, setValidationError, step, dashboardTitle, location, selectedSocCode)) {
			if (step === 4) {
				createDashBoard(
					dashboardTitle,
					location,
					useOnetSearch,
					currentLocationDescription,
					csvFile,
					customersFile,
					locationPDFFilesNames,
					locationDescription,
					selectedSocCode,
					setLoading,
					setTrigger,
					setStep,
					setOpenBiModal,
					currentDashboardData,
				);
			} else {
				setStep((prevStep) => Math.min(4, prevStep + 1));
			}
		}
	};

	const handleLocationPDFOnChangeFile = (locationFiles) => {
		if (locationFiles && locationFiles.files && locationFiles.files?.length > 0) {
			// Handle drag & drop event.
			setLocationPDFFilesErrorUpload();

			setLocationPDFFilesNames((prevFiles) => {
				const existingLocation = prevFiles.find((loc) => loc.location_index === locationFiles.location_index);

				if (existingLocation) {
					// Append new files to existing location index
					return prevFiles.map((loc) =>
						loc.location_index === locationFiles.location_index
							? {
									...loc,
									files: [...loc.files, ...Array.from(locationFiles.files).map((file) => file.name)],
							  }
							: loc,
					);
				} else {
					// Add new location index entry
					return [
						...prevFiles,
						{
							location_index: locationFiles.location_index,
							files: Array.from(locationFiles.files).map((file) => file.name),
						},
					];
				}
			});

			uploadLocationPDFS(locationFiles.location_index, locationFiles.files);
		} else if (locationFiles.e && locationFiles.e.target && locationFiles.e.target.files) {
			// Handle file input change
			setLocationPDFFilesErrorUpload();

			setLocationPDFFilesNames((prevFiles) => {
				const existingLocation = prevFiles.find((loc) => loc.location_index === locationFiles.location_index);

				if (existingLocation) {
					// Append new files to existing location index
					return prevFiles.map((loc) =>
						loc.location_index === locationFiles.location_index
							? {
									...loc,
									files: [
										...loc.files,
										...Array.from(locationFiles.e.target.files).map((file) => file.name),
									],
							  }
							: loc,
					);
				} else {
					// Add new location index entry
					return [
						...prevFiles,
						{
							location_index: locationFiles.location_index,
							files: Array.from(locationFiles.e.target.files).map((file) => file.name),
						},
					];
				}
			});

			uploadLocationPDFS(locationFiles.location_index, locationFiles.e.target.files);
		} else if (locationFiles.e && locationFiles.e.name) {
			// Handle other file upload logic (if necessary)
			setLocationPDFFilesErrorUpload();
			setLocationPDFFilesNames(locationFiles.e.name);
			uploadLocationPDFS(locationFiles.e);
		} else {
			// Clear or reset
			removeLocationPDFS(locationPDFFiles);
			setLocationPDFFiles();
			setLocationPDFFilesNames();
		}
	};

	const removeCSV = async (path) => {
		const formData = new FormData();
		formData.append("employee_csv_path", path);
		const res = await removeUploadedCsv(formData);
	};

	const uploadLocationPDFS = async (location_index, files) => {
		if (!files) return;

		let uploadedFiles = [];
		let uploadProgress = [];

		for (const [index, file] of Array.from(files).entries()) {
			const formData = new FormData();
			formData.append(`files[${location_index}][${index}]`, file);

			const config = {
				onUploadProgress: (progressEvent) => {
					uploadProgress[index] = progressEvent;
					setlocationPDFFilesUpload({ ...uploadProgress });
				},
			};

			const res = await validatePDFFiles(formData, config.onUploadProgress);

			if (res?.status === "success") {
				const uploadedFile = res?.data[0];
				uploadedFiles.push(uploadedFile);
			} else {
				setLocationPDFFilesErrorUpload(res.message);
				break;
			}
		}

		if (uploadedFiles.length > 0) {
			setLocationPDFFilesNames((prevFiles) => {
				const updatedFiles = prevFiles.map((locationFile) => {
					if (locationFile.location_index === location_index) {
						return { ...locationFile, files: uploadedFiles };
					}
					return locationFile;
				});

				return [...updatedFiles];
			});

			setTimeout(() => {
				setLocationPDFFiles(() => [...uploadedFiles]);
			}, 0);
		}
	};

	const removeUploadedFile = (fileToRemove) => {
		const updatedArray = locationPDFFilesNames
			.map((locationFile) => {
				const updatedFiles = locationFile.files.filter((file) => {
					return file !== fileToRemove;
				});

				return { ...locationFile, files: updatedFiles };
			})
			.filter((locationFile) => locationFile.files.length > 0);

		setLocationPDFFilesNames(updatedArray);
	};

	const renderStepContent = () => {
		switch (step) {
			case 1:
				return (
					<>
						{csvApproval !== null && approvingCsvUpload === true && proceedWithCsvUpload === false ? (
							<div style={{ width: "100%" }} className='form-row csvApproval'>
								<div>
									<h6>Please review imported locations before processing</h6>
									{numberOfRows > maxAllowed && (
										<p className='text-danger'>
											The uploaded file contains {numberOfRows} rows, exceeding the maximum limit
											of {maxAllowed} rows. {numberOfRows - maxAllowed} row(s) were skipped.
										</p>
									)}

									<ul className='csvRows'>
										{csvApproval.map((csvRow, i) => (
											<ol key={i} className='group-field'>
												{csvRow.Location && (
													<div>
														<b>Location: </b> {csvRow.Location}
													</div>
												)}
												{csvRow.status && (
													<div>
														<b>Status:</b> {csvRow.status}
													</div>
												)}
												{csvRow.area_price && (
													<div>
														<b>Area price: </b>${csvRow.area_price}
													</div>
												)}
												{csvRow.opex_value && (
													<div>
														<b>Opex: </b> ${csvRow.opex_value}
													</div>
												)}
												{csvRow.leasing_company && (
													<div>
														<b>Leasing company:</b> {csvRow.leasing_company}
													</div>
												)}
												{csvRow.note && (
													<div>
														<b>Note: </b> {csvRow.note}
													</div>
												)}
											</ol>
										))}
									</ul>
								</div>
							</div>
						) : (
							<>
								{csvProcessingLoading && (
									<>
										<div style={{ textAlign: "center" }} className='card-title'>
											<h6>
												Processing imported locations. <br></br>Please wait.
											</h6>
											<Loader />
										</div>
									</>
								)}
								<DashboardTitle
									dashboardTitle={dashboardTitle}
									handleOnDashboardTitleChange={handleOnDashboardTitleChange}
									error={validationError.title.error}
									errorMessage={validationError.title.message}
								/>
								<AddEditLocations
									removePDFFile={() => false}
									handleOnCurrentLocationDiscriptionChange={(name, value) =>
										handleOnCurrentLocationDescriptionChange(
											name,
											value,
											currentLocationDescription,
											setCurrentLocationDescription,
											checkUrlsForMaliciousContent,
										)
									}
									cancelPDFUpload={removeUploadedFile}
									errorFileUpload={errorFileUpload}
									dynamicArray={dynamicArray}
									proposedLocations={location}
									pdfFileName={locationPDFFilesNames}
									handleLocationPDFFiles={handleLocationPDFOnChangeFile}
									proposedLocationIndex={proposedLocationIndex}
									location={location}
									setLocation={setLocation}
									locationDescription={locationDescription}
									setLocationDescription={setLocationDescription}
									setProposedLocationIndex={setProposedLocationIndex}
									checkValueIsPositiveDecimal={checkValueIsPositiveDecimal}
									maxLengthTextArea={500}
									maxAllowed={maxAllowed}
									handleOnChangeFile={(event) =>
										handleOnChangeLocationFile(
											event,
											setApprovingCsvUpload,
											setProceedWithCsvUpload,
											setCsvError,
											setTempData,
											setCSVProcessingLoading,
											setCsvApproval,
											setTempResults,
											setNumberOfRows,
											maxAllowed,
										)
									}
									csvError={csvError}
									error={validationError.location.error}
									errorMessage={validationError.location.message}
									progressFileUpload={locationPDFFilesUpload}
								/>
							</>
						)}
					</>
				);
			case 2:
				return (
					<CSVUpload
						csvFileName={csvFileName}
						progressFileUpload={progressFileUpload}
						errorFileUpload={errorFileUpload}
						handleOnChangeFile={(event) =>
							handleOnChangeFile(
								event,
								setCsvFile,
								setCsvFileName,
								setErrorFileUpload,
								(file) =>
									uploadCSV(
										file,
										setProgressFileUpload,
										setCsvFile,
										setCsvFileName,
										setErrorFileUpload,
									),
								() => removeCSV(csvFile),
							)
						}
					/>
				);
			case 3:
				return (
					<CustomersUpload
						csvFileName={customersFileName}
						progressFileUpload={customersProgressFileUpload}
						errorFileUpload={customersErrorFileUpload}
						handleOnChangeFile={(event) =>
							handleCustomersOnChangeFile(
								event,
								setCustomersErrorFileUpload,
								setCustomersFileName,
								setCustomersFile,
								(file) =>
									uploadCustomers(
										file,
										setCustomersProgressFileUpload,
										setCustomersFile,
										setCustomersFileName,
										setCustomersErrorFileUpload,
									),
								() => removeCustomers(customersFile),
							)
						}
					/>
				);
			case 4:
				return (
					<>
						{validationError.socCode.error && (
							<div>
								<span className='sub-text' style={{ color: "red" }}>
									* {validationError.socCode.message}
								</span>
							</div>
						)}
						{isSearchJobTitleEnabled(user) ? (
							<InputToggle
								labelClass='text-dark'
								label='Enable "search by job title"'
								footNote='Allows searching by Job Title and Occupation'
								isChecked={useOnetSearch}
								onValueChange={() => {
									setUseOnetSearch(!useOnetSearch);
									setSelectedSocCode([]);
								}}
							/>
						) : (
							""
						)}
						{useOnetSearch ? (
							<AddSocCodeOnet
								socCodes={socCodes}
								setSocCodes={(newSocCodes) => setSocCodes(newSocCodes)}
								selectedSocCode={selectedSocCode}
								setSelectedSocCode={(newSelectedSocCode) =>
									handleSocCodeUpdate(newSelectedSocCode, setSelectedSocCode)
								}
								setSelectedSOCCodes={(newSelectedSocCode) =>
									handleSocCodeUpdate(newSelectedSocCode, setSelectedSocCode)
								}
								errorSOCValidation={errorSOCValidation}
								dashboardDataSocs={[]}
								selectedSOCCodes={selectedSocCode}
							/>
						) : (
							<AddSocCode
								socCodeList={socCodeList}
								selectedSocCode={selectedSocCode}
								handleToggleCheckboxSocCode={(code) =>
									handleToggleCheckboxSocCode(code, selectedSocCode, setSelectedSocCode)
								}
								errorSOCValidation={errorSOCValidation}
							/>
						)}
					</>
				);
			default:
				return null;
		}
	};

	return (
		<CleanModal
			key={"make-bi-dashboard"}
			title={`Create Business Insights Dashboard From Score Insights Dashboard (step ${step} of 4)`}
			trigger={openBiModal}
			setTrigger={setOpenBiModal}
			customButton={[
				<div key='save-div' className='justify-end'>
					{csvApproval !== null && approvingCsvUpload === true && proceedWithCsvUpload === false ? (
						<>
							<button
								style={{ marginRight: "24px" }}
								className='btn primary'
								onClick={() => {
									setProceedWithCsvUpload(true);
									setApprovingCsvUpload(false);
								}}
							>
								Approve
							</button>
							<button
								className='btn secondary outline'
								onClick={() => {
									setApprovingCsvUpload(false);
									setProceedWithCsvUpload(false);
									setCSVProcessingLoading(false);
								}}
							>
								Cancel
							</button>
						</>
					) : (
						<>
							{step !== 1 && (
								<button
									onClick={() => {
										setStep((prevStep) => Math.max(1, prevStep - 1));
									}}
									style={{ marginRight: "24px" }}
									className='btn secondary outline'
								>
									Back
								</button>
							)}
							{step !== 4 && (
								<button
									onClick={handleNextStep}
									style={{ marginRight: "24px" }}
									className='btn secondary outline'
								>
									Next
								</button>
							)}
							{step === 4 && (
								<button onClick={handleNextStep} className='btn secondary outline'>
									Create Business Insights Dashboard
								</button>
							)}
						</>
					)}
				</div>,
			]}
		>
			{renderStepContent()}
		</CleanModal>
	);
}

export default CreateCloneBusinessInsightsDashboard;
