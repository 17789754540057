import { debounce } from "lodash";

import {
	createDashboard,
	getSOCCode,
	validateCSVFile,
} from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";

export const handleSocCodeUpdate = (newSelectedSocCode, setSelectedSocCode) => {
	setSelectedSocCode(newSelectedSocCode);
};
import * as XLSX from "xlsx";

import { getBackendGeocode } from "../../services/dashboards/townson-dashboard.services/geocode.service";
import { toast } from "react-toastify";

export const handleOnCurrentLocationDescriptionChange = (
	name,
	value,
	currentLocationDescription,
	setCurrentLocationDescription,
	checkUrlsForMaliciousContent,
) => {
	if (name === "note") {
		const regex = new RegExp(
			"https?:\\/\\/[^\\s/$.?#].[^\\s]*|ftps?:\\/\\/[^\\s/$.?#].[^\\s]*|(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
			"gi",
		);

		const urls = value.match(regex) || [];
		if (urls.length > 0) {
			const debouncedCheckUrlsForMaliciousContent = debounce(checkUrlsForMaliciousContent, 2000);
			debouncedCheckUrlsForMaliciousContent(urls);
		}
	}

	setCurrentLocationDescription({
		...currentLocationDescription,
		[name]: value,
	});
};

export const uploadCSV = async (file, setProgressFileUpload, setCsvFile, setCsvFileName, setErrorFileUpload) => {
	if (!file) return;
	const formData = new FormData();
	formData.append("employee_csv", file);
	const res = await validateCSVFile(formData, setProgressFileUpload);
	if (res?.status === "success") {
		setCsvFile(res?.data?.employee_csv_path);
	} else {
		setCsvFileName();
		setCsvFile();
		setErrorFileUpload(res.message);
	}
};

export const handleOnChangeFile = (event, setCsvFile, setCsvFileName, setErrorFileUpload, uploadCSV, removeCSV) => {
	if (event && event.target && event.target.files[0]) {
		setErrorFileUpload();
		setCsvFileName(event.target.files[0]?.name);
		uploadCSV(event.target.files[0]);
	} else if (event && event.name) {
		setErrorFileUpload();
		setCsvFileName(event.name);
		uploadCSV(event);
	} else {
		removeCSV();
		setCsvFile();
		setCsvFileName();
	}
};

const allowedExtensions = ["csv", "xlsx", "xls", "xlsm", "xlsb"];

export const handleOnChangeLocationFile = (
	event,
	setApprovingCsvUpload,
	setProceedWithCsvUpload,
	setCsvError,
	setTempData,
	setCSVProcessingLoading,
	setCsvApproval,
	setTempResults,
	setNumberOfRows,
	maxAllowed,
) => {
	if (event && event.target && event.target.files[0]) {
		setApprovingCsvUpload(false);
		setProceedWithCsvUpload(false);
		const fileExtension = event.target.files[0]?.name.split(".")[event.target.files[0]?.name.split(".").length - 1];
		if (!allowedExtensions.includes(fileExtension)) {
			setCsvError(["* Please input a csv/xlsx/xls file"]);
			return;
		}
		setCsvError([]);

		const f = event.target.files[0];
		const reader = new FileReader();

		reader.onload = async (evt) => {
			const bstr = evt?.target?.result;
			const wb = XLSX.read(bstr, { type: "binary" });

			if (wb?.SheetNames?.length < 1) {
				setCsvError(["* Please select valid proposed location file"]);
				return;
			}
			const wsname = wb?.SheetNames[0];
			const ws = wb?.Sheets[wsname];

			const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "", blankrows: false });

			setTempData(data);

			if (data?.length < 1) {
				setCsvError(["* Please select valid proposed location file"]);
				return;
			}

			// Check csv and confirm first row keys match expected format
			if (
				data[0][0] !== "Property Address" ||
				data[0][1] !== "City" ||
				data[0][2] !== "State" ||
				data[0][3] !== "Zip" ||
				data[0][4] !== "Building Status" ||
				(data[0][5] !== "Rent" && data[0][5] !== "Rent/SF/Yr") ||
				(data[0][6] !== "OPEX" && data[0][6] !== "Ops Expense Per SF") ||
				(data[0][7] !== "Leasing Company" && data[0][7] !== "Leasing Company Name") ||
				data[0][9] !== "Is Current? (true/false)"
			) {
				setCsvError(["* Please select valid proposed location file"]);
				return;
			}

			const results = [];
			const totalRows = data.length - 1;
			setNumberOfRows(totalRows);

			const maxRows = totalRows > maxAllowed ? maxAllowed + 1 : data.length;
			for (let i = 1; i < maxRows; i++) {
				var obj = {
					Location: `${data[i][0]}${data[i][0] && ", "}${data[i][1]}${data[i][1] && ", "}${data[i][2]} ${
						data[i][3]
					}`,
					status: data[i][4],
					area_price: data[i][5].toString().replace("$", ""),
					opex_value: data[i][6].toString().replace("$", ""),
					leasing_company: data[i][7],
					note: data[i][8],
					is_current: data[i][9],
				};

				if (data[i][2].length > 2) {
					setCsvError(["* Please use state abreviation"]);
					return;
				}

				results.push(obj);
			}
			if (results.length > 0) {
				setCSVProcessingLoading(true);
				setCsvApproval(results);
				setApprovingCsvUpload(true);
				setTempResults(results);
			}
		};
		reader.readAsBinaryString(f);
	}
};

export const uploadCustomers = async (
	file,
	setCustomersProgressFileUpload,
	setCustomersFile,
	setCustomersFileName,
	setCustomersErrorFileUpload,
) => {
	if (!file) return;
	const formData = new FormData();
	formData.append("customer_csv", file);
	const res = await validateCSVFile(formData, setCustomersProgressFileUpload);
	if (res?.status === "success") {
		setCustomersFile(res?.data?.customer_csv_path);
	} else {
		setCustomersFileName();
		setCustomersFile();
		setCustomersErrorFileUpload(res.message);
	}
};

export const handleCustomersOnChangeFile = (
	event,
	setCustomersErrorFileUpload,
	setCustomersFileName,
	setCustomersFile,
	uploadCustomers,
	removeCustomers,
) => {
	if (event && event.target && event.target.files[0]) {
		setCustomersErrorFileUpload();
		setCustomersFileName(event.target.files[0]?.name);
		uploadCustomers(event.target.files[0]);
	} else if (event && event.name) {
		setCustomersErrorFileUpload();
		setCustomersFileName(event.name);
		uploadCustomers(event);
	} else {
		removeCustomers();
		setCustomersFile();
		setCustomersFileName();
	}
};

export const resumeProcessing = async (
	setCSVProcessingLoading,
	setCsvError,
	setLocation,
	setProposedLocationIndex,
	setLocationDescription,
	tempResults,
	maxAllowed,
	tempData,
) => {
	const tempLocation = [];
	const tempDescription = [];
	const input = [];
	for (let i = 0; i < tempResults?.length; i++) {
		if (i < maxAllowed && tempResults[i]?.Location) {
			input.push({
				address: `${tempResults[i]?.Location.replace("#", "")}.json?limit=${maxAllowed}&countrySet=US`,
			});
		}
	}
	setCSVProcessingLoading(true);

	const apiData = await getBackendGeocode({
		locations: input,
	});
	const error = ["ERROR:"];
	for (let i = 0; i < tempResults?.length; i++) {
		if (i < maxAllowed && tempData[i + 1][3]) {
			const apiResponse = apiData?.batchItems[i]?.response?.results.find(
				(arg) =>
					arg?.address?.freeformAddress.includes(tempData[i + 1][1]) &&
					arg?.address?.freeformAddress.includes(tempData[i + 1][2]) &&
					arg?.address?.freeformAddress.includes(tempData[i + 1][3]),
			);

			if (apiResponse?.position) {
				tempDescription.push({
					note: tempResults[i]["note"] ? tempResults[i]["note"] : "",
					opex_value: parseFloat(tempResults[i]["opex_value"]) > 0.0 ? tempResults[i]["opex_value"] : "",
					area_price: parseFloat(tempResults[i]["area_price"]) > 0.0 ? tempResults[i]["area_price"] : "",
					leasing_company: tempResults[i]["leasing_company"] ? tempResults[i]["leasing_company"] : "",
					status:
						tempResults[i]["status"] === "Existing" ||
						tempResults[i]["status"] === "Under Construction" ||
						tempResults[i]["status"] === "Proposed"
							? tempResults[i]["status"]
							: "",
					is_current: tempResults[i]["is_current"] === true ? true : false,
				});
				tempLocation.push(apiResponse);
			} else {
				error.push(
					`${i + 1} - ${tempData[i + 1][0]}, ${tempData[i + 1][1]}, ${tempData[i + 1][2]}, ${
						tempData[i + 1][3]
					} not available`,
				);
			}
		}
	}
	if (error?.length > 1) {
		setCsvError(error);
	}
	if (tempLocation?.length > 0) {
		setLocation({
			...location,
			proposedLocations: tempLocation,
		});
		setProposedLocationIndex(tempDescription?.length);
		setLocationDescription(tempDescription);
	}
	setCSVProcessingLoading(false);
};

export const handleToggleCheckboxSocCode = (code, selectedSocCode, setSelectedSocCode) => {
	const exists = selectedSocCode.find((item) => item.id === code.id);

	if (exists) {
		setSelectedSocCode(selectedSocCode.filter((item) => item.id !== code.id));
	} else {
		setSelectedSocCode([...selectedSocCode, code]);
	}
};

export const logFormData = (formData) => {
	console.log("Form Data Contents:");
	for (let [key, value] of formData.entries()) {
		if (value instanceof File) {
			console.log(`${key}: File - ${value.name}`);
		} else if (typeof value === "object") {
			console.log(`${key}: ${JSON.stringify(value)}`);
		} else {
			console.log(`${key}: ${value}`);
		}
	}
};

export const getSocCodeList = async (setSocCodeList) => {
	const res = await getSOCCode();
	if (res?.code === 200) {
		setSocCodeList(res.data);
	}
};

export const createDashBoard = async (
	dashboardTitle,
	location,
	useOnetSearch,
	currentLocationDescription,
	csvFile,
	customersFile,
	locationPDFFilesNames,
	locationDescription,
	selectedSocCode,
	setLoading,
	setTrigger,
	setStep,
	setOpenBiModal,
	currentDashboardData,
) => {
	setLoading(true);
	setTrigger(false);
	const bodyFormData = new FormData();

	bodyFormData.append(
		"dashboard_title",
		dashboardTitle !== "" ? dashboardTitle : location?.currentLocation?.address?.freeformAddress,
	);
	bodyFormData.append("use_search", useOnetSearch ? true : false);
	bodyFormData.append("created_from", "score_insights");
	bodyFormData.append("created_from_id", currentDashboardData?._id);

	if (location?.currentLocation?.address?.freeformAddress) {
		bodyFormData.append(
			"current_location",
			location?.currentLocation?.address?.freeformAddress ? location.currentLocation.address.freeformAddress : "",
		);
		bodyFormData.append("latitude", location.currentLocation.position.lat || "");
		bodyFormData.append("longitude", location.currentLocation.position.lon || "");
		bodyFormData.append("opex_value_current", currentLocationDescription.opex_value_current || "");
		bodyFormData.append("is_current_current", currentLocationDescription.is_current || false);
		bodyFormData.append(
			"status_current",
			currentLocationDescription ? currentLocationDescription.status || "" : "",
		);
		bodyFormData.append("notes_current", currentLocationDescription ? currentLocationDescription.note || "" : "");
		bodyFormData.append(
			"leasing_company_current",
			currentLocationDescription ? currentLocationDescription.leasing_company || "" : "",
		);
		bodyFormData.append(
			"area_price_current",
			currentLocationDescription ? currentLocationDescription.area_price || "" : "",
		);
	}

	if (csvFile) {
		bodyFormData.append("employee_csv_path", csvFile);
	}
	if (customersFile) {
		bodyFormData.append("customer_csv_path", customersFile);
	}

	if (locationPDFFilesNames) {
		locationPDFFilesNames.forEach((locationData) => {
			locationData.files.forEach((file, index) => {
				bodyFormData.append(`pdfs[${locationData.location_index}][${index}]`, file);
			});
		});
	}

	location.proposedLocations.forEach((item, index) => {
		if (item?.address) {
			bodyFormData.append("proposed_location[]", item.address.freeformAddress || "");
			bodyFormData.append("proposed_location_lat[]", item.position.lat || "");
			bodyFormData.append("proposed_location_long[]", item.position.lon || "");

			bodyFormData.append(
				"opex_value[]",
				locationDescription[index] ? locationDescription[index].opex_value || "" : "",
			);
			bodyFormData.append(
				"is_current[]",
				locationDescription[index] ? locationDescription[index].is_current || false : false,
			);
			bodyFormData.append("status[]", locationDescription[index] ? locationDescription[index].status || "" : "");
			bodyFormData.append(
				"leasing_company[]",
				locationDescription[index] ? locationDescription[index].leasing_company || "" : "",
			);
			bodyFormData.append("notes[]", locationDescription[index] ? locationDescription[index].note || "" : "");
			bodyFormData.append(
				"area_price[]",
				locationDescription[index] ? locationDescription[index].area_price || "" : "",
			);
		}
	});

	selectedSocCode.forEach((item) => {
		const socCodeData = useOnetSearch ? JSON.stringify(item) : item.id;
		bodyFormData.append("soc_code[]", socCodeData || "");
	});

	logFormData(bodyFormData);

	const res = await createDashboard(bodyFormData);
	if (res?.code === 200) {
		toast.success("Succesfully created Business Insights dashboard");
		setOpenBiModal(false);
		setStep(1);
	}
};

export const validateStep = (validationError, setValidationError, step, dashboardTitle, location, selectedSocCode) => {
	let isValid = true;
	const newValidationError = { ...validationError };

	if (step === 1) {
		if (!dashboardTitle.trim()) {
			newValidationError.title.error = true;
			isValid = false;
		} else {
			newValidationError.title.error = false;
		}

		if (location.proposedLocations.length === 0 || location.proposedLocations.every((loc) => loc === null)) {
			newValidationError.location.error = true;
			isValid = false;
		} else {
			newValidationError.location.error = false;
		}
	}

	if (step === 4) {
		if (selectedSocCode.length === 0) {
			newValidationError.socCode.error = true;
			isValid = false;
		} else {
			newValidationError.socCode.error = false;
		}
	}

	setValidationError(newValidationError);
	return isValid;
};
